import MuiMenuItem, { MenuItemProps } from "@mui/material/MenuItem";
import { handleKeyDown } from "core/model/accessibility/keyboardActions";

export const MenuItem = ({
  children,
  onClick,
  ...props
}: MenuItemProps & { component?: "div" }) => {
  return (
    <MuiMenuItem
      {...props}
      tabIndex={0}
      onClick={onClick}
      onKeyDown={handleKeyDown({
        onConfirm: (event) => {
          onClick?.(
            event as unknown as React.MouseEvent<HTMLLIElement, MouseEvent>,
          );
        },
      })}
    >
      {children}
    </MuiMenuItem>
  );
};
