import { TRACK_EVENTS } from "core/consts";
import { AnyObject } from "core/types";
import { RecareNameLogo } from "ds_legacy/components/RecareLogo";
import { VerticalLayout } from "ds_legacy/materials/layouts";
import { LARGE, dp, padding } from "ds_legacy/materials/metrics";
import { Display } from "ds_legacy/materials/typography";
import { ReactNode, useEffect } from "react";
import { useTracking } from "react-tracking";
import styled from "styled-components";
import { useTranslations } from "translations";
import Translations from "translations/types";

export const Page = styled.div<{ flexBoundaries?: boolean }>`
  display: flex;
  max-width: 100%;
  max-height: 100%;
  width: ${({ flexBoundaries }) => (flexBoundaries ? "100%" : "100vw")};
  height: ${({ flexBoundaries }) => (flexBoundaries ? "100%" : "100vh")};
  flex: 1;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  overflow: auto;
  background-color: ${(props) => props.theme.palette.primary.dark};
  padding: ${padding(2.5)};
  text-align: center;
  box-sizing: border-box;
`;

export default function ErrorPage({
  context,
  ErrorComponent,
  flex,
  getErrorLabel,
  pageName,
  reason,
  redirectTo,
}: {
  ErrorComponent?: ReactNode;
  context?: AnyObject;
  flex?: boolean;
  getErrorLabel?: (translations: Translations) => string;
  pageName?: string;
  reason?: string;
  redirectTo?: string;
}) {
  const translations = useTranslations();
  const { trackEvent } = useTracking();

  useEffect(() => {
    trackEvent({
      name: TRACK_EVENTS.ERROR_PAGE,
      page_name: pageName,
      context,
      reason,
      redirect_to: redirectTo,
    });
  }, []);
  const errorLabel = getErrorLabel?.(translations);

  if (ErrorComponent && errorLabel == null) return <>{ErrorComponent}</>;

  return (
    <Page flexBoundaries={flex}>
      <RecareNameLogo size={LARGE} />
      <VerticalLayout maxWidth="80%">
        <Display light>
          {errorLabel ?? translations.general.status.error}
        </Display>
        {ErrorComponent && (
          <div style={{ marginTop: dp(30) }}>{ErrorComponent}</div>
        )}
      </VerticalLayout>
    </Page>
  );
}
