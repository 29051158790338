import {
  MAX_AGE,
  MIN_AGE,
  PAGINATION_COUNT,
  PAGINATION_START,
} from "core/consts";
import {
  RECEIVER_FILTER_SETTING_PERSIST,
  USER_FILTER_AGE_INTERVAL,
  USER_FILTER_CARESEEKERS,
  USER_FILTER_CARE_LEVELS,
  USER_FILTER_DISTANCE_FROM_PROVIDER,
  USER_FILTER_ELECTIVE,
  USER_FILTER_GENDERS,
  USER_FILTER_HAS_CAPACITY,
  USER_FILTER_IS_DIRECT,
  USER_FILTER_PATIENT_TYPES,
  USER_FILTER_SENDERS,
  USER_FILTER_SERVICES,
  USER_FILTER_SOCIAL_WORKERS,
  USER_FILTER_SOLUTIONS,
  USER_FILTER_SPECIALIZATIONS,
  USER_FILTER_START_DATE,
  USER_FILTER_START_DATE_RANGE,
  USER_FILTER_STATIONS,
  USER_FILTER_TAB,
  USER_FILTER_TRANSITIONAL_CARE,
  USER_FILTER_TREATMENT_LOCATION,
  USER_FILTER_WEIGHT_INTERVAL,
  USER_FILTER_WITH_CONSULTANTS,
  USER_FILTER_WITH_PROVIDERS,
} from "dsl/organisms/Filters/consts";
import { UserFilters } from "dsl/organisms/Filters/types";
import {
  setBasicArrayFilterParam,
  setBooleanQueryParam,
  setIntervalQueryParam,
  setKeyBasicValueQueryParam,
  setPaginationCountQueryParam,
  setPaginationStartQueryParam,
  setSendersFilterQueryParam,
  setStartDateRangeQueryParam,
} from "./helpers";

export default function toFilterQueryString<
  Filters extends Partial<UserFilters>,
>(filters: Filters, options?: { byTab: boolean }): string {
  const query: string[] = [];

  const filterNames = Object.keys(filters) as (keyof Filters)[];
  filterNames.forEach((filterName) => {
    switch (filterName) {
      case USER_FILTER_TAB:
        if (options?.byTab && filters.tab) {
          query.push(
            setKeyBasicValueQueryParam({
              filterName: USER_FILTER_TAB,
              filterValue: filters.tab,
            }),
          );
        }
        break;
      case USER_FILTER_SOLUTIONS:
      case USER_FILTER_CARESEEKERS:
      case USER_FILTER_GENDERS:
      case USER_FILTER_CARE_LEVELS:
      case USER_FILTER_PATIENT_TYPES:
      case USER_FILTER_SPECIALIZATIONS:
      case USER_FILTER_STATIONS:
      case USER_FILTER_SOCIAL_WORKERS:
      case USER_FILTER_SERVICES:
        query.push(
          setBasicArrayFilterParam({
            filterName,
            filters,
          }),
        );
        break;
      case USER_FILTER_SENDERS:
        query.push(setSendersFilterQueryParam(filters.senders));
        break;
      case USER_FILTER_TRANSITIONAL_CARE:
      case USER_FILTER_IS_DIRECT:
      case USER_FILTER_HAS_CAPACITY:
      case USER_FILTER_WITH_CONSULTANTS:
      case USER_FILTER_WITH_PROVIDERS:
      case USER_FILTER_ELECTIVE:
        query.push(
          setBooleanQueryParam({
            filterName,
            filters,
          }),
        );
        break;
      case USER_FILTER_START_DATE:
        query.push(
          setKeyBasicValueQueryParam({
            filterName: USER_FILTER_START_DATE,
            filterValue: filters.start_date,
          }),
        );
        break;
      case USER_FILTER_DISTANCE_FROM_PROVIDER:
        query.push(
          setKeyBasicValueQueryParam({
            filterName: USER_FILTER_DISTANCE_FROM_PROVIDER,
            filterValue: (filters?.distance ?? 1) * 1000,
          }),
        );
        break;
      case USER_FILTER_TREATMENT_LOCATION:
        query.push(
          setKeyBasicValueQueryParam({
            filterName: USER_FILTER_TREATMENT_LOCATION,
            filterValue: filters?.zipcode?.value,
          }),
        );
        break;
      case USER_FILTER_START_DATE_RANGE:
        query.push(setStartDateRangeQueryParam(filters?.start_date_range));
        break;
      case USER_FILTER_WEIGHT_INTERVAL:
        query.push(
          setIntervalQueryParam(
            USER_FILTER_WEIGHT_INTERVAL,
            filters?.weight_interval,
          ),
        );
        break;
      case USER_FILTER_AGE_INTERVAL:
        query.push(
          setIntervalQueryParam(
            USER_FILTER_AGE_INTERVAL,
            filters?.age_interval,
            { min: MIN_AGE, max: MAX_AGE },
          ),
        );
        break;
      case RECEIVER_FILTER_SETTING_PERSIST:
        break;
      case PAGINATION_COUNT:
        query.push(setPaginationCountQueryParam(filters.count));
        break;
      case PAGINATION_START:
        query.push(setPaginationStartQueryParam(filters.start));
        break;
      default: {
        console.error(
          `trying to convert unsupported filter: ${filterName.toString()} with values: ${
            filters?.[filterName] || "no value"
          }`,
        );
      }
    }
  });
  const truthyQuerys = query.truthy();

  return `${truthyQuerys.join("&")}`;
}
